body, html {
  margin: 0;

  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background-color: #f7f7f7;
}

.flex {
  display: flex;
}

.rete-background {
  z-index: -100 !important;
}

.rete-background.default {
  background-size: 16px 16px !important;
  background-image: linear-gradient(to right, #ddd 1px, transparent 1px), linear-gradient(to bottom, #ddd 1px, transparent 1px) !important;
}

.connection {
  overflow: visible !important;
  width: 12px;
  height: 12px;
}

.connection .main-path {
  fill: transparent !important;
  stroke-width: 6px !important;
  stroke: #004e73 !important;
}

.connection .main-path:hover {
  stroke-width: 7px !important;
  stroke: #0082bd !important;
}

.socket-output-carouselrichcard .main-path{
  fill: transparent !important;
  stroke-width: 6px !important;
  stroke: #a3b8d1 !important;
  /* stroke-dasharray: 6px !important; */
}

.socket-output-carouselrichcard .main-path:hover {
  fill: transparent !important;
  stroke-width: 6px !important;
  stroke: #0082bd !important;
  /* stroke-dasharray: 6px !important; */
}

.ngx-contextmenu .dropdown-menu {
  background-color: white;
  padding: 0;
  margin: 2px 0 0;
  outline: none;
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.3);
}

.ngx-contextmenu li {
  display: block;
}

.ngx-contextmenu li:first-child {
  border-top: none;
}

.ngx-contextmenu a {
  color: darkslategrey;
  text-decoration: none;
  display: block;
  padding: 0.4em 1em;
}

.ngx-contextmenu .passive {
  padding: 0.4em 0.8em !important;
}

.ngx-contextmenu a:hover, .ngx-contextmenu .active {
  color: darkslategrey;
  background-color: lightgray;
}

.snackbar-success  {
  background-color: #c1eba6;
  color: #3c3c3c;
  margin-top: 90px !important;
}

.snackbar-success .mat-simple-snackbar-action {
  color: #3c3c3c;
}

.snackbar-error {
  background-color: firebrick;
  color: white;
  margin-top: 90px !important;
}

.snackbar-error .mat-simple-snackbar-action {
  color: #ffec7e;
}

.snackbar-warning {
  background-color: gold;
  color: black;
  margin-top: 90px !important;
}

.snackbar-warning .mat-simple-snackbar-action {
  color: black;
}

.mat-tooltip {
  font-size: 12px !important;
  white-space: pre-line;
}

.mat-drawer {
  background-color: #f7f7f7;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.18);
}

mat-divider {
  margin: 1em 0 .6em 0 !important;
  border-top-color: rgba(0, 0, 0, 0.12) !important;
  border-width: 2px !important;
}

.fill_space {
  flex: 1 1 auto;
}

.mat-menu-item {
  height: 36px !important;
  line-height: 36px !important;
}

button.menu {
  margin: 2px 0.8em 0 4px;
  padding: 0 4px 0 4px;
  min-width: unset;
}

button:hover .fa.delete-button, .delete-button:hover {
  color: #e22d25;
}

mat-icon, .fa {
  transition: color .15s ease-in;
}

mat-icon.add:hover {
  color: #8cc540;
}

mat-form-field {
  padding-top: 10px;
}

.details-node-name {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 6px;
}
