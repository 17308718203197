@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// custom pattete http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
$lmg-primary: (
  50 : #e0eef4,
  100 : #b3d6e3,
  200 : #80bad0,
  300 : #4d9ebd,
  400 : #268aaf,
  500 : #0075a1,
  600 : #006d99,
  700 : #00628f,
  800 : #005885,
  900 : #004574,
  A100 : #a2d3ff,
  A200 : #6fbbff,
  A400 : #3ca2ff,
  A700 : #2396ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$flow-app-primary: mat-palette($lmg-primary);
$flow-app-accent:  mat-palette($lmg-primary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$flow-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$flow-app-theme: mat-light-theme($flow-app-primary, $flow-app-accent, $flow-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($flow-app-theme);
